import React from "react"

export default function NewsletterSuccess() {
  return (
    <div>
      <meta http-equiv="refresh" content="6; url=/contact"></meta>
      <p>THX</p>
    </div>
  )
}
